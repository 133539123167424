.header {
  display: flex;
  width: 100%;
  position: fixed;
  justify-content: space-between;
  align-items: center;
  /* background: #ffffff; */
  box-shadow: 0px 4px 4px 0px rgb(0 0 0 / 0.05);
  padding: 15px 56px;
  z-index: 99;
  height: 72px;
}

/* headrr logo */
.header-left {
  display: flex;
  align-items: center;
}

.header-left img {
  max-width: 174.57px;
  max-height: 72px;
}

/* --- header Menu start--- */
.header-center {
  font-size: 16px;
  color: #919397;
}

.header-center ul {
  display: flex;
}

.header-center li {
  margin: 0 5px;
}

.header-center li span {
  line-height: 22px;
  margin: 15px 0px;
}

.header-center svg {
  margin-right: 8px;
}

.header-center a {
  text-decoration: none;
  cursor: pointer;
  padding: 10px;
}

.header-center .dropdown-arrow {
  margin-left: 7px;
  display: flex;
}

/* hover effect */
.header-center li a:hover,
.header-center li.active a {
  color: var(--color-theme);
}

.header-center li a:hover svg path,
.header-center li.active a svg path {
  fill: var(--color-theme);
}

/* .header-center li a:hover .dropdown-arrow {
    transform: rotateX(180deg);
    transition: all .2s linear;
} */
.header-center li a:hover .dropdown-arrow svg path,
.header-center li.active a .dropdown-arrow svg path {
  stroke: var(--color-theme);
  fill: none;
}

/* dropdown */
.header-center .dropdown-content {
  min-width: 128px;
  position: absolute;
  top: 72px;
  padding: 12px 0;
  /* background: #ffffff; */
  border-radius: 6px;
  z-index: 1;
  box-shadow: 0px 0px 8px 0px rgb(0 0 0 / 16%);
}

.header-center .dropdown-content a {
  padding: 4px 16px;
  font-size: 16px;
  line-height: 20px;
  /* color: #919397 !important; */
}

.header-center .dropdown-content a:hover {
  background-color: var(--color-theme_1);
  color: var(--color-theme);
}

.header-center .request-demo span {
  color: var(--color-theme);
  border-bottom: 2px solid var(--color-theme);
}

.header-centers .dropdown-contents a:hover::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.6);
  /* White with 60% opacity to blend */
  mix-blend-mode: multiply;
  /* Blends the white overlay with the background */
  pointer-events: none;
  /* Ensures click events pass through to the link */
}

/*menu start */
.mobileHamburger {
  display: none;
}
.burger {
  position: relative;
  width: 40px;
  height: 23px;
  background: transparent;
  cursor: pointer;
  display: block;
}

.burger input {
  display: none;
}

.burger span {
  display: block;
  position: absolute;
  height: 4px;
  width: 100%;
  /* background: black; */
  border-radius: 9px;
  opacity: 1;
  left: 0;
  transform: rotate(0deg);
  transition: 0.25s ease-in-out;
}

.burger span:nth-of-type(1) {
  top: 0px;
  transform-origin: left center;
}

.burger span:nth-of-type(2) {
  top: 50%;
  transform: translateY(-50%);
  transform-origin: left center;
}

.burger span:nth-of-type(3) {
  top: 100%;
  transform-origin: left center;
  transform: translateY(-100%);
}

.burger input:checked ~ span:nth-of-type(1) {
  transform: rotate(45deg);
  top: -3px;
  left: 0px;
}

.burger input:checked ~ span:nth-of-type(2) {
  width: 0%;
  opacity: 0;
}

.burger input:checked ~ span:nth-of-type(3) {
  transform: rotate(-45deg);
  top: 19px;
  left: -1px;
}

/*menu end*/

/* --- header Menu End--- */

.header .home-icon {
  display: none;
}

.header .home-btn {
  display: block;
  height: 44px;
  width: 172px;
  font-size: 17px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.header-right .btn-primary.login-signup {
  width: 172px;
  height: 44px;
}

/* onboard Header */
.onboard-header .header-center {
  display: none;
}

/* profile login header */
.profile-detail {
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
}

.profile-detail .header-content {
  display: flex;
  align-items: center;
  margin: 25px 0px;
}

.profile-detail .profile-photo {
  width: 32px;
  height: 32px;
  border-radius: 100%;
}

.profile-detail .profile-photo img,
.profile-detail .profile-photo svg {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.profile-detail h4 {
  font-size: 16px;
  line-height: 22px;
  color: #23282e;
  margin: 0 7px 0 12px;
  font-family: "firaSans-medium";
  font-weight: 500;
}

.profile-detail .dropdown-content {
  box-shadow: 0px 0px 8px 0px rgb(0 0 0 / 16%);
  position: absolute;
  padding: 8px 16px;
  border-radius: 6px;
  background: #ffffff;
  width: 160px;
  top: 80px;
  left: -18px;
}

.profile-detail .dropdown-content::before {
  content: "";
  height: 10px;
  width: 10px;
  position: absolute;
  background-color: #ffffff;
  top: -6px;
  left: 45%;
  border-top: 1px solid rgb(0 0 0 / 8%);
  border-left: 1px solid rgb(0 0 0 / 8%);
  transform: rotate(45deg);
}

.profile-detail .dropdown-content a {
  font-size: 14px;
  line-height: 22px;
  margin: 8px 0;
  color: #919397;
}

.profile-detail .dropdown-content a:hover {
  color: #23282e;
}

.profile-detail .dropdown-content .profile-detail {
  display: none;
}

/* Admin Hamburger */
.admin-Hamburger {
  display: flex;
  flex-direction: column;
  margin-right: 18px;
  cursor: pointer;
}

.admin-Hamburger span {
  width: 24px;
  height: 2px;
  margin: 3px 0;
  background-color: #23282e;
}

.admin-Hamburger.close {
  margin: 0;
}

.admin-Hamburger.close span:first-child {
  transform: rotate(45deg);
  margin: -1px;
}

.admin-Hamburger.close span:last-child {
  transform: rotate(-45deg);
  margin: -1px;
}

.onboard-header .admin-Hamburger {
  display: none;
}

.header-right {
  display: flex;
  align-items: center;
  gap: 15px;
}

.header-right .notifications .notification-icon {
  width: 25px;
  cursor: pointer;
  margin-top: 5px;
  margin-right: 3px;
}

.header-right .notifications .notification-icon-sub {
  content: "";
  display: inline-block;
  width: 8px;
  height: 8px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  background-color: var(--color-theme);
  margin-left: -8px;
  /* transform: translateX(-5px); */
  margin-top: -20px;
}

.header-right .notifications .notification-container {
  transition: all 300ms ease;
}

.header-right .notifications .notification-container:hover {
  transform: scale(1.1);
}

.header-right .notifications .items {
  display: none;
  position: absolute;
  width: 340px;
  height: 400px;
  background: #fff;
  border: 1px solid rgb(0 0 0 / 8%);
  border-radius: 5px;
  right: 20px;
  top: 65px;
  z-index: 1;
  box-shadow: 0px 8px 24px 2px rgba(0, 0, 0, 0.16);
}

.header-right .notifications .background {
  background: transparent;
  display: none;
  position: absolute;
  left: 0;
  top: 70px;
  height: 100vh;
  width: 100vw;
  z-index: 0;
}

.header-right .notifications .items.show ~ .background {
  display: block;
}

.header-right .notifications .items.show {
  display: block;
  padding: 20px 10px;
}

.header-right .notifications .items::before {
  content: "";
  height: 10px;
  width: 10px;
  position: absolute;
  background-color: #ffffff;
  top: -6px;
  left: 50%;
  border-top: 1px solid rgb(0 0 0 / 8%);
  border-left: 1px solid rgb(0 0 0 / 8%);
  transform: rotate(45deg);
}

.header-right .notifications .items .top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #bdbdbd;
}

.header-right .notifications .items .top h3 {
  font-size: 18px;
  line-height: 22px;
  color: #23282e;
  font-family: "firaSans-medium";
  font-weight: 600;
}

.header-right .notifications .items .top h4.mark {
  font-size: 14px;
  line-height: 22px;
  color: var(--color-theme);
  margin: 0 7px 0 12px;
  font-family: "firaSans-medium";
  font-weight: 500;
  cursor: pointer;
}

.header-right .notifications .items .notification-items {
  height: 320px;
  overflow-y: auto;
}

.header-right .notifications .items .notification-items::-webkit-scrollbar {
  width: 5px;
}

.header-right
  .notifications
  .items
  .notification-items::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.header-right
  .notifications
  .items
  .notification-items::-webkit-scrollbar-thumb {
  background: #888;
}

.header-right
  .notifications
  .items
  .notification-items::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.header-right .notifications .items .notification-item {
  padding: 10px;
  cursor: pointer;
  border-bottom: 1px solid rgb(145, 148, 151, 0.5);
}

.header-right .notifications .items .notification-item:hover {
  background: #f6f9ff;
}

.header-right .notifications .items .notification-item.unread h4::before {
  content: "";
  display: inline-block;
  width: 8px;
  height: 8px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  background-color: var(--color-theme);
  margin-left: -9px;
  margin-right: 2px;
}

.header-right .notifications .items .notification-item h4 {
  font-size: 14px;
  line-height: 22px;
  color: rgb(35, 40, 46, 0.7);
  font-family: "firaSans-medium";
  font-weight: 500;
  width: 100%;
  /* word-wrap: break-word; */
  /* word-break: break-all; */
}

.header-right .notifications .items .notification-item.unread h4 {
  color: rgb(35, 40, 46);
}

.header-right .notifications .items .notification-item .date {
  font-size: 12px;
  line-height: 12px;
  color: rgb(35, 40, 46, 0.5);
  font-family: "firaSans-medium";
  font-weight: 300;
}

.header-right .notifications .items .notification-item.unread .date {
  color: rgb(35, 40, 46, 0.8);
}

.header-right .notifications .no-notification {
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.header-right .notifications .no-notification h4 {
  font-size: 16px;
  line-height: 22px;
  color: #23282e;
  opacity: 0.5;
  margin: 0 7px 0 12px;
  font-family: "firaSans-medium";
  font-weight: 500;
}

@media screen and (max-width: 1100px) {
  .header-left img {
    max-width: 150px;
  }
}

@media screen and (max-width: 1020px) {
  .header-left img {
    max-width: 120px;
  }
}

@media screen and (max-width: 991px) {
  .header {
    padding: 14px 16px;
    height: 65px;
  }

  .header-right .notifications .items::before {
    left: 82%;
  }

  .mobileHamburger {
    display: flex;
    flex-direction: column;
    margin-right: 13px;
    cursor: pointer;
  }

  .mobileHamburger span {
    width: 30px;
    height: 3px;
    margin: 3px 0;
    /* background-color: #23282e; */
  }

  .mobileHamburger.close {
    margin: 0;
  }

  .mobileHamburger.close span:first-child {
    transform: rotate(45deg);
    margin: -1px;
  }

  .mobileHamburger.close span:last-child {
    transform: rotate(-45deg);
    margin: -1px;
  }

  .onboard-header .mobileHamburger {
    display: none;
  }

  /* header-left */
  .header-left {
    display: flex;
  }

  .header-left img {
    max-width: 138.83px;
    max-height: 65px;
    object-fit: contain;
  }

  /* header center */
  .header-center {
    position: absolute;
    background: rgba(0, 0, 0, 0.6);
    top: -2500%;
    left: 0;
    right: 0;
    padding: 0;
    border-top: 1px solid rgb(0 0 0 / 10%);
    height: 100vh;
  }

  .header-center ul {
    flex-direction: column;
    padding: 15px 0;
    background: #fff;
    max-height: calc(100% - 75px);
    overflow-y: auto;
  }

  .header-center li {
    margin: 0;
  }

  .header-center li:last-child {
    margin: 0;
  }

  .header-center .dropdown-arrow {
    margin-left: auto;
  }

  .header-center .dropdown-content {
    position: relative;
    top: 0;
    box-shadow: none;
    padding-left: 20px;
    padding-bottom: 0;
  }

  .header-center .dropdown-content a {
    padding: 5px 16px;
  }

  .header-center li span {
    margin: 0;
  }

  .header-center .dropdown-content a:hover {
    background-color: transparent;
  }

  .header-center .dropdown-content a:last-child {
    padding-bottom: 0;
  }

  .header-center.show {
    top: 60px;
  }

  /* header right */
  .header-right {
    display: flex;
    align-items: center;
  }

  .header-right .btn-primary.login-signup {
    width: 120px;
    height: 37px;
    font-size: 14px;
  }

  /* mobile hamburger */
  /* profile */
  .profile-detail h4,
  .profile-detail svg.drop-down,
  .profile-detail.isopen .profile-photo {
    display: none;
  }

  .profile-detail {
    position: static;
  }

  .profile-detail .dropdown-content {
    width: 100%;
    left: 0;
    border-radius: 0px;
    padding: 0;
    border-top: 1px solid rgb(0 0 0 / 10%);
    box-shadow: none;
    background: rgba(0, 0, 0, 0.6);
    height: 100vh;
    top: 60px;
  }

  .profile-detail .dropdown-content::before {
    content: none;
  }

  .profile-detail .dropdown-content .profile-detail,
  .profile-detail .dropdown-content .profile-detail h4 {
    display: flex;
  }

  .profile-detail .dropdown-content .profile-detail,
  .profile-detail .dropdown-content a {
    margin: 0 0 24px;
  }

  .dropdown-background {
    background: #fff;
    padding: 30px 16px 1px;
  }
}

@media (min-width: 992px) and (max-width: 1140px) {
  .header-center li {
    margin: 0 8px;
  }

  .header-center a {
    padding: 15px 0;
  }
}

@media (min-width: 1141px) and (max-width: 1220px) {
  .header-center a {
    padding: 15px 10px;
  }
}

.dropdown-content {
  display: none;
}

.dropdown:hover .dropdown-content {
  display: block;
}

@media screen and (max-width: 600px) {
  .header-right .notifications .items .top {
    flex-direction: row;
  }
}

@media screen and (max-width: 500px) {
  .header .home-btn {
    display: none;
  }

  .header .home-icon {
    display: block;
    margin: 0 10px 0 0;
  }

  .header-right .notifications .items {
    width: 90%;
    left: 50%;
    transform: translateX(-50%);
  }
}
/* 
@media (max-width: 768px) {
  .transparentStyleDropdown{
    background: rgba(0, 0!important;
  }
} */

.default {
  background: white;
}

.alternative {
  /* background: rgba(0, 0, 0, 0.06); */
  box-shadow: none !important;
}

.text-default {
  color: black;
}

.text-alternative {
  color: #868686;
}

.LightBtn {
  padding: 10px 10px;
  cursor: pointer;
  border-radius: 6px;
  min-width: 172px !important;
  background: none;
  /* border: 1px solid #868686 !important;
  color: #868686; */
  border: 1px solid white;
  color: white;
  min-height: 44px;
  font-size: 16px;
  font-family: "FiraSans-Medium";
  font-weight: 500;
}
@media (max-width:650px){
  .LightBtn{
    max-height: 37px !important;
    min-height: 37px !important;
    height: 37px !important; 
    max-width: 120px !important;
    min-width: 120px !important;
    width: 120px !important;
    font-size: 14px;

  }
}
.textMenuItem {
  font-size: 16px;
  line-height: 15px;
  color: #919397;
}
.dropdownMainTitle {
  font-size: 16px;
  line-height: 22px;
  color: #23282e;
  margin: 0 7px 0 12px;
  font-family: "firaSans-medium";
  font-weight: 500;
}
.mobileMainTitle {
  color: #23282e !important;

  font-family: "firaSans-medium";
  font-weight: 500;
  font-size: 15px;
}

.btn-orange {
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 4px;

  background: var(--color-theme);
  border: 1px solid var(--color-theme);
  color: white;
}

.activeMenuLink {
  color: var(--color-theme);
}

#mega-menu-full-dropdown {
  box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px;
}
.textMenuItem:hover {
  color: var(--color-theme) !important;
}
.activeLink {
  color: var(--color-theme) !important;
}
.activeSvg {
  stroke: var(--color-theme) !important;
}
.activeSvgFill {
  fill: var(--color-theme) !important;
}

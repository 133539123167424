/* TAbs */
.reports-tab ul li {
  flex: 1;
}

.team-white-card {
  padding: 12px 0px;
  background: #fff;
  margin-bottom: 16px;
}

/* teamaction table */
.teams-table-sect {
  margin-top: 4px;
}

.teams-table-sect thead th {
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: #23282e;
  opacity: 0.8;
  font-family: "firaSans-medium";
  padding-left: 26px;
}

.team-table thead .btn {
  padding: 5px 10px;
}

.team-table td.delete-marks {
  text-align: center;
}

.teams-role {
  font-family: "firaSans-semibold";
}

.teams-search {
  display: flex;
  margin: 30px 0 0;
  align-items: center;
  justify-content: space-between;
  /* background: linear-gradient(90deg, #4587DC 0%, #9DC9F5 100%); */
  background: linear-gradient(90deg, #E6F4FC, #7FCFFF, #E6F4FC);
  margin-bottom: 20px;
  border-radius: 6px;
  padding: 20px;
}

.teams-search .btn.download {
  height: 40px;
}

.teams-search .download-container {
  margin-left: auto;
  display: flex;
  flex-direction: row;
}

.teams-search .input-icon {
  margin-left: 20px;
  display: flex;
  align-items: center;
  height: 100%;
}

.teams-table-sect h4 {
  font-size: 20px;
  line-height: 24px;
  color: #23282e;
  font-family: "firaSans-regular";
  font-weight: normal;
}

.team-btn {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.team-btn button {
  border: none;
}

.edit-btn {
  width: 24px;
  height: 24px;
  border-radius: 4px;
  padding: 0;
  border: 0px solid teamparent;
  cursor: pointer;
}

.edit-btn img {
  margin: 0;
}

.team-head {
  display: flex;
}

.teams-head h1 {
  font-size: 24px;
}

.team-head button {
  margin-left: auto;
  width: 164px;
}

.teams-user {
  font-family: "firaSans-semibold";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
}

.teams-user-count {
  font-family: "firaSans-regular";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
}

.team-table .action-team span {
  margin: 0px 8px;
  cursor: pointer;
}

.select-all-btn {
  width: max-content;
}

@media screen and (max-width: 991px) {

  /* table responsive */
  .teams-table-sect {
    margin-top: 24px;
  }

  .teams-table-sect h4 {
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: #23282e;
    font-family: "firaSans-semibold";
  }

  .m-teams-table .c-white-card {
    margin-top: 16px;
    box-shadow: none;
  }

  .team-m-card {
    padding: 16px !important;
    margin-top: 16px;
    border: 1px solid rgba(35, 40, 46, 0.16);
    box-shadow: none;
    margin-bottom: 0 !important;
  }

  .teams-m-title {
    font-family: "firaSans-semibold";

    font-weight: 500;
    font-size: 18px;
    margin-bottom: 6px;
    display: flex;
    align-items: center;

    color: #23282e;
  }

  .team-m-top {
    display: flex;
    justify-content: space-between;
  }

  .team-m-bottom {
    display: flex;
    margin: inherit;
    justify-content: space-between;
  }

  .team-m-bottom .delete-marks {
    display: flex;
    align-items: flex-end;
    padding: 3px;
  }

  .team-m-top h5 {
    font-size: 16px;
    line-height: 19px;
    color: #23282e;
    margin: 0;
    font-family: "firaSans-regular";
    font-weight: normal;
  }

  .team-m-top span {
    font-size: 14px;
    line-height: 14px;
    color: #23282e;
    margin-left: 3px;
    margin-top: 3px;
    opacity: 0.6;
  }

  .team-m-top h4 {
    font-size: 16px;
    line-height: 19px;
    color: #23282e;
    font-family: "firaSans-medium";
    font-weight: 500;
  }

  .team-m-top {
    margin-bottom: 10px;
  }

  .team-m-bottom span {
    font-size: 14px;
    line-height: 17px;
    color: #23282e;
  }

  .team-m-mode {
    background: #f1f1f1;
    border-radius: 4px;
    border: 1px solid #f1f1f1;
    display: inline-flex;
    padding: 2.5px 4px;
  }

  .teams-m-name {
    font-family: "firaSans-medium";
    font-size: 16px;
  }

  .team-m-initiate {
    opacity: 0.6;
    font-size: 12px;
  }

  .team-m-user {
    font-family: "firaSans-regular";
    font-size: 14px;
    margin-top: 10px;
    word-wrap: break-word;
    word-break: break-all;
  }

  .teams-m-head h1 {
    font-size: 20px;
    margin-top: 10px;
  }

  .teams-m-head button {
    margin-left: auto;
  }
}

/* team table */

.team-table h5 {
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: #23282e;
  margin-bottom: 16px;
  font-family: "firaSans-medium";
}

.team-table.c-white-card {
  margin-bottom: 10px;
}

/* table desktop */
.team-table.mb0 h5 {
  margin-bottom: 0;
}

.team-table table {
  width: 100%;
  border-spacing: 0em 0.7em;
}

.team-table th {
  text-align: left;
}

.team-table thead tr {
  background: #F8F9FD;
  border-radius: 6px;
}

.team-table table tr:hover td {
  background: #F8F9FD;
}

.team-table tbody td {
  /* border-top: 1px solid rgba(35, 40, 46, 0.1);
  border-bottom: 1px solid rgba(35, 40, 46, 0.1); */
  padding: 8px 24px;
  cursor: pointer;

  font-size: 16px;
  font-weight: bold;
}

.teams-table-sect .team-table tbody td {
  padding: 8px 24px;
}

.team-table tbody td:first-child {
  /* border-left: 1px solid rgba(35, 40, 46, 0.1); */
  border-radius: 6px 0 0 6px;
}

.team-table tbody td:last-child {
  /* border-right: 1px solid rgba(35, 40, 46, 0.1); */
  border-radius: 0 6px 6px 0;
}

.team-table thead tr th:first-child {
  border-radius: 6px 0 0 6px;
}

.team-table thead tr th:last-child {
  border-radius: 0 6px 6px 0;
}

.team-table thead th {
  padding: 9px 24px;
  font-size: 14px;
  line-height: 22px;
  color: rgb(35 40 46 / 80%);
  padding-left: 26px;
}

.team-table tbody td:nth-child(6),
.team-table thead th:nth-child(6) {
  text-align: center;
}

.transaction-table-sect .team-table thead th:nth-child(4) {
  text-align: left;
}

.table-arrow {
  display: flex;
  justify-content: space-between;
}

.table-arrow-img {
  margin-left: 20px;
}

.team-viewall {
  text-align: center;
  margin-top: 8px;
}

.team-viewall button {
  font-family: "firaSans-regular";
  height: 40px;
}

.team-table .table-desc {
  align-items: center;
  display: flex;
}

.team-table .action-group span,
.m-teams-table .action-group span {
  margin: 0px 8px;
  cursor: pointer;
}

/* table responive */
.table-responsive {
  display: none;
  flex-direction: column;
}

.team-t-card {
  width: 100%;
}

.team-t-top {
  display: flex;
  justify-content: space-between;
  padding-bottom: 16px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.table-detail {
  display: flex;
}

.table-detail .table-img {
  width: 40px;
  height: 40px;
  border-radius: 4px;
  margin-right: 12px;
  background: #f1f1f1;
}

.table-detail .table-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.table-detail h4 {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  color: #23282e;
  margin-bottom: 2px;
  font-family: "firaSans-medium";
}

.table-detail span {
  font-size: 14px;
  line-height: 17px;
  /* color: #23282e; */
  /* opacity: 0.5; */
}

.table-upadte {
  padding: 2px 4px 2px 14px;
  border-radius: 4px;
  font-size: 12px;
  line-height: 14px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 18px;
  position: relative;
  font-family: "firaSans-medium";
  text-transform: capitalize;
}

.table-upadte::before {
  content: "";
  width: 5px;
  height: 5px;
  border-radius: 100%;
  background-color: #fff;
  position: absolute;
  left: 5px;
  top: 7px;
}

.table-upadte.live {
  background: rgba(42, 176, 112, 0.1);
  color: #2ab070;
}

.table-upadte.live::before {
  background: #2ab070;
}

.table-upadte.complete {
  background: rgba(226, 85, 105, 0.1);
  color: #e25569;
}

.table-upadte.complete::before {
  background: #e25569;
}

.team-t-bottom {
  padding-top: 16px;
  display: flex;
  justify-content: space-between;
}

.team-t-left,
.team-t-right {
  flex: 1;
}

.team-t-bottom span {
  font-size: 14px;
  line-height: 17px;
  color: #23282e;
  opacity: 0.5;
}

.team-t-bottom h6 {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #23282e;
  margin-top: 11px;
  font-family: "firaSans-medium";
}

.mspan .btn {
  display: none;
}

.animated-checkbox {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  width: 50px;
  cursor: pointer;
}

@media screen and (max-width: 991px) {
  .team-table.c-white-card {
    padding: 0;
    background: transparent;
    box-shadow: none;
  }

  .teams-search .download-container {
    flex-direction: column;
  }

  .teams-search .btn.download {
    width: 100%;
  }

  .teams-search .input-icon {
    margin-left: 0;
    margin-top: 20px;
  }

  .msearch {
    display: block;
  }

  .team-table table {
    display: none;
  }

  .table-responsive {
    display: flex;
  }

  .team-viewall {
    display: none;
  }

  .team-t-right h6 {
    font-weight: normal;
    font-family: "firaSans-regular";
  }

  .mspan {
    margin-bottom: 10px;
  }

  .mspan .btn {
    display: flex;
    float: right;
    margin-top: -20px;
    padding: 5px 10px;
  }

  .animated-checkbox {
    width: 16px;
  }
}

@media screen and (max-width: 600px) {
  .m-teams-table .delete-marks .form-select {
    display: block;
  }
}